import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "url", "autoplay", "controls", "loop", "fs", "disablekb", "modestbranding", "iv_load_policy", "rel", "enablejsapi", "styleType", "mute", "isHero", "isGreaterThanPhone", "imgClassName", "src", "mobileImageUrl", "alt", "title", "srcSetSettingImg", "imageOptimisationType", "isLessThanDesktop", "mobileCardBackgroundColor", "mobileHeroVariation"];
var __jsx = React.createElement;
/**
 * Represents a Video Atom for Youtube video.
 * @author kkum
 */import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import styles from './Video.style';
// import { YOUTUBE_URL } from '../../../constants/globalConstants';
import YouTube from 'react-youtube';
import YoutubeSvg from './YoutubeSvg';
import Image from '../../atoms/Image';
var StyleSection = styled.section.withConfig({
  componentId: "sc-1aa91ad-0"
})(["", ";"], styles);
/**
 * Video component.
 * @param {string} className - Class of the tag.
 * @param {string} url - video key.
 * @param {number} autoplay - (0/1) autoplay video or not.
 * @param {number} controls - (0/1) controls to be shown or not.
 * @param {number} loop - (0/1) video to be looped or not.
 * @param {number} fs - (0/1) enable or disable fullscreen functionality.
 * @param {number} disablekb - (0/1) enable or disable keyboard controls.
 * @param {number} modestbranding - (0/1) show or hide youtube logo.
 * @param {number} iv_load_policy - video annotations to be shown by default or not.
 * @param {number} rel - (0/1) show related videos or not.
 * @param {number} enablejsapi - (0/1) enable or disable player control from Iframe or javascript.
 * @param {auto} styleType - heroCarousel image
 */
var Video = function Video(_ref) {
  var className = _ref.className,
    url = _ref.url,
    autoplay = _ref.autoplay,
    controls = _ref.controls,
    loop = _ref.loop,
    fs = _ref.fs,
    disablekb = _ref.disablekb,
    modestbranding = _ref.modestbranding,
    iv_load_policy = _ref.iv_load_policy,
    rel = _ref.rel,
    enablejsapi = _ref.enablejsapi,
    styleType = _ref.styleType,
    mute = _ref.mute,
    isHero = _ref.isHero,
    isGreaterThanPhone = _ref.isGreaterThanPhone,
    imgClassName = _ref.imgClassName,
    src = _ref.src,
    mobileImageUrl = _ref.mobileImageUrl,
    alt = _ref.alt,
    title = _ref.title,
    srcSetSettingImg = _ref.srcSetSettingImg,
    imageOptimisationType = _ref.imageOptimisationType,
    isLessThanDesktop = _ref.isLessThanDesktop,
    mobileCardBackgroundColor = _ref.mobileCardBackgroundColor,
    mobileHeroVariation = _ref.mobileHeroVariation,
    other = _objectWithoutProperties(_ref, _excluded);
  var splitedURL = url && url.split('&')[0];
  var opts = {
    playerVars: {
      controls: controls,
      rel: rel,
      enablejsapi: enablejsapi,
      playlist: splitedURL,
      autoplay: autoplay,
      iv_load_policy: iv_load_policy,
      modestbranding: modestbranding,
      disablekb: disablekb,
      loop: loop,
      fs: fs,
      mute: mute
    }
  };
  var _useState = useState(false),
    isVideoPlaying = _useState[0],
    setIsVideoPlaying = _useState[1];
  var _useState2 = useState(false),
    isVideoClicked = _useState2[0],
    setIsVideoClicked = _useState2[1];
  var videoEl = useRef(null);
  var onThumbnailClick = function onThumbnailClick() {
    videoEl.current.internalPlayer.playVideo();
    setIsVideoClicked(true);
  };
  var onPlayerStateChange = function onPlayerStateChange(event) {
    event.target.setVolume(0);
    event.target.mute();
    event.target.playVideo();
  };
  var onPlayerReady = function onPlayerReady(event) {
    if (isHero) {
      event.target.setVolume(0);
      event.target.mute();
      event.target.playVideo();
      setInterval(function () {
        var current_time = event.target.getCurrentTime();
        if (current_time > 0.6) {
          setIsVideoPlaying(true);
        }
        if (current_time >= event.target.getDuration() - 1) {
          event.target.seekTo(0, true);
          event.target.setVolume(0);
          event.target.mute();
        }
      }, 500);
    }
  };
  return url ? isHero ? mobileHeroVariation !== 'full' && isGreaterThanPhone || mobileHeroVariation === 'full' && !isLessThanDesktop ? __jsx("div", {
    className: "nva-video c-video--hero",
    style: {
      opacity: isVideoPlaying === false ? '0' : ''
    },
    tabIndex: "-1",
    "aria-hidden": "true"
  }, __jsx(YouTube, {
    videoId: splitedURL,
    "aria-hidden": "true",
    tabIndex: -1,
    opts: opts,
    onReady: onPlayerReady,
    onStateChange: onPlayerStateChange
  })) : null : __jsx("div", null, __jsx("div", {
    style: {
      display: isVideoClicked === false ? 'none' : ''
    }
  }, __jsx(YouTube, {
    videoId: splitedURL,
    opts: opts,
    onReady: onPlayerReady,
    ref: videoEl
  })), !isVideoClicked && __jsx(StyleSection, {
    className: className
  }, __jsx("button", {
    className: "nva-video__yt-img yt-img",
    onClick: onThumbnailClick
  }, __jsx(YoutubeSvg, null), __jsx(Image, {
    src: src,
    mobileImageUrl: mobileImageUrl,
    alt: alt,
    title: title,
    styleType: styleType,
    className: imgClassName,
    srcSetSettingImg: srcSetSettingImg,
    imageOptimisationType: imageOptimisationType,
    isLessThanDesktop: isLessThanDesktop,
    isGreaterThanPhone: isGreaterThanPhone,
    mobileCardBackgroundColor: mobileCardBackgroundColor
  })))) : '';
};
Video.defaultProps = {
  styleType: '',
  rel: 0,
  iv_load_policy: 3,
  modestbranding: 1,
  disablekb: 0,
  enablejsapi: 1,
  autoplay: 0,
  controls: 1,
  loop: 0,
  fs: 1,
  mute: 0
};
export default styled(Video).withConfig({
  componentId: "sc-1aa91ad-1"
})(["", ";"], styles);
export { Video as VideoVanilla };